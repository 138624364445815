import React from 'react'
import Plx from 'react-plx';
import VizSensor from 'react-visibility-sensor';
import SEO from '../../components/seo'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import ThreeContainer from '../../components/threeContainer'

//import ChangeBlockVariable from '../../components/changeBlockVariable'
import MouseButton from '../../components/mouseButton'
import { Helmet } from "react-helmet"


//Assets
import LogoSF from "../../images/logo-test-ao.jpg"
import LaptopAon from "../../images/laptop_aon.png"
import PhoneAon from "../../images/phone_aon.png"
import IpadSF from "../../images/ipad_stroz.png"

//import BigLaptop from "../../images/big_laptop.png"

//Video
import CyberSecVideo from "../../videos/Sequence_CyberTicTacToe.mp4"
import CyberSecVideo960 from "../../videos/Sequence_CEP_960.mp4"
import LaptopSFVideo from "../../videos/Laptop_SF.mp4"

/*
const laptopMiddleParallaxData = [
  {
    start: '.BottomImageTwoMiddle',
    duration: '50vh',
    properties: [
      {
        startValue: -5,
        endValue: 0,
        unit: 'vw',
        property: 'translateX',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  }
]

const laptopRightParallaxData = [
  {
    start: '.BottomImageTwoRight',
    duration: '50vh',
    properties: [
      {
        startValue: 5,
        endValue: 0,
        unit: 'vw',
        property: 'translateX',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  }
]
*/

const laptopMiddleParallaxData = [
  {
    start: '.BottomImageThreeMiddle',
    duration: '50vh',
    properties: [
      {
        startValue: 2.5,
        endValue: 0,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  }
]

const laptopLeftParallaxData = [
  {
    start: '.BottomImageThreeLeft',
    duration: '50vh',
    properties: [
      {
        startValue: -10,
        endValue: 0,
        unit: 'vw',
        property: 'translateX',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  }
]

const laptopRightParallaxData = [
  {
    start: '.BottomImageThreeRight',
    duration: '50vh',
    properties: [
      {
        startValue: 10,
        endValue: 0,
        unit: 'vw',
        property: 'translateX',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  }
]

const sliderParallaxData = [
  {
    start: '.SecondBlock',
    startOffset:'99vh',
    duration: '1vh',
    properties: [
      {
        startValue: 100,
        endValue: 0,
        unit: 'vh',
        property: 'height'
      }
    ]
  }
]




const MASONARY_DURATION = '20vh'

const masonaryTile1ParallaxData = [
  {
    start: 'self',
    startOffset: '5vh',
    duration: MASONARY_DURATION,
    properties: [
      {
        startValue: 25,
        endValue: 0,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      }
    ],
  }
]

const bioTile1ParallaxData = [
  {
    start: 'self',
    startOffset: '30vh',        
    duration: MASONARY_DURATION,
    properties: [
      {
        startValue: 5,
        endValue: 0,
        unit: 'vw',
        property: 'translateX',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      }
    ],
  }
]

const bioTile2ParallaxData = [
  {
    start: 'self',
    startOffset: '40vh',    
    duration: MASONARY_DURATION,
    properties: [
      {
        startValue: 5,
        endValue: 0,
        unit: 'vw',
        property: 'translateX',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      }
    ],
  }
]
const bioTile3ParallaxData = [
  {
    start: 'self',
    startOffset: '50vh',    
    duration: MASONARY_DURATION,
    properties: [
      {
        startValue: 5,
        endValue: 0,
        unit: 'vw',
        property: 'translateX',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      }
    ],
  }
]

const bioTile4ParallaxData = [
  {
    start: 'self',
    startOffset: '60vh',    
    duration: MASONARY_DURATION,
    properties: [
      {
        startValue: 5,
        endValue: 0,
        unit: 'vw',
        property: 'translateX',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      }
    ],
  }
]

const masonaryTile2ParallaxData = [
  {
    start: 'self',
    startOffset: '10vh',
    duration: MASONARY_DURATION,
    properties: [
      {
        startValue: 25,
        endValue: 0,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      }
    ],
  }
]

const masonaryTile3ParallaxData = [
  {
    start: 'self',
    startOffset: '15vh',
    duration: MASONARY_DURATION,
    properties: [
      {
        startValue: 25,
        endValue: 0,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      }
    ],
  }
]

const masonaryTile4ParallaxData = [
  {
    start: 'self',
    startOffset: '20vh',
    duration: MASONARY_DURATION,
    properties: [
      {
        startValue: 25,
        endValue: 0,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      }
    ],
  }
]

const masonaryHeaderCenterParallaxData = [
  {
    start: 'self',
    duration: '60vh',
    properties: [
      {
        startValue: -25,
        endValue: 0,
        unit: 'vh',
        property: 'translateY',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  }
]


const verticalLineTestimonialParallaxData = [
  {
    start: 'self',
    startOffset: '30vh',
    duration: '150vh',
    properties: [
      {
        startValue: 0,
        endValue: 150,
        unit: 'vh',
        property: 'height',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  }
]

const BigLaptopParallaxData = [
  {
    start: '.BigLaptopBlock',
    duration: '50vh',
    properties: [
      {
        startValue: 1.5,
        endValue: 1.5,
        property: 'scale',
      },
    ],
  },

  {
    start: '.BigLaptopBlock',
    startOffset: '50vh',
    duration: '50vh',
    properties: [
      {
        startValue: 1.5,
        endValue: 0.75,
        property: 'scale',
      },
    ],
  },
]

export default class ParallaxPage extends React.Component {
  constructor(props) {
    super(props)
     //Video
    this.BannerVideoRef = React.createRef()
        this.BannerVideoRef = React.createRef()


   
    this.state = {
      threeColorOne: [57,1,100],
      threeColorTwo: [236,47,75],
     }
  
  }  
  

  render() {
    return (
    <>
        <SEO title="Services" description="Brand advisory, Strategy ,Branding and design ,Brand positioning ,Brand design ,Core messaging ,Name generation ,Marketing strategy and planning ,Go-to-market activation ,Creative direction ,Content strategy and planning ,Sales enablement ,Social Media strategy ,Channel management ,MarTech integration ,Digital production ,UX design ,Website build ,Video / Motion graphics ,App / Digital platforms ,Advertising concepts ,Integrated campaigns ,Analytics"/>
        <Helmet>
          <title>Services</title>
        </Helmet>     
        {/*Slider Block*/}
        <div className="ContentBlock Centered SliderBlock">
        <VizSensor 
              partialVisibility={true} 
              onChange={(isVisible) => {
                isVisible ? this.BannerVideoRef.current.play() : this.BannerVideoRef.current.pause()
              }}
            >           
        <Plx parallaxData={ sliderParallaxData } className="SliderBlockWrapper">
          <div className="TopBanner">
                    <div>
                        <video ref={this.BannerVideoRef} autoPlay loop muted playsInline preload="metadata">
                          <source src={CyberSecVideo}/>                          
                          <source src={CyberSecVideo960} media="all and (max-width: 960px)"/>                                                     
                          </video>
                        <div className="ImageOverlay"></div>
                        <h4><strong>Cybersecurity</strong><br/>Marketing</h4>
                    </div>
          </div>
          </Plx>
          </VizSensor>
          <MouseButton></MouseButton>

        </div>


{/*CEP Block */}              
<div className="ContentBlock Centered CyberSecBlock SecondBlock" id="SecondBlock">
                  <ThreeContainer visualType={'wave'} colorOne={this.state.threeColorOne} colorTwo={this.state.threeColorTwo}></ThreeContainer>

                  <div className="HeaderWrapper">
                    <h1 className="WhiteHighlight">Full Service<br/>Cybersecurity Marketing</h1>
                  </div>
                  <h5>
                  As a burgeoning industry, cybersecurity marketing has it's unique different challenges and opportunities. 
                  We believe that a deep understanding of the markets and audiences helps us tackle those challenges and harness the opportunities.
                  </h5>
                  <h5>
                    <strong>Our agile, data-drive and result-focused, approach helps cybersecurity companies unravel the puzzle of brand and marketing</strong> with focused, prioritized and adaptive strategies, creative strength, compelling language, dynamic assets, and collaborative sales and marketing enablement.
                  </h5>
                  <AnchorLink className="AnchorLink ArrowLink" href="#contact"><h3>Change Happens Everywhere. <br/>Get yours here.</h3></AnchorLink>
                  {/*
                  <div className="BottomImages">
                        <Plx parallaxData={ laptopMiddleParallaxData }>              
                          <img src={LaptopAon} className="BottomImageTwoMiddle" alt="Laptop #3"/>
                        </Plx>                            
                        <Plx parallaxData={ laptopRightParallaxData }>                            
                          <img src={PhoneAon} className="BottomImageTwoRight" alt="Laptop #2"/>
                        </Plx>
                  </div>
                  */}

                  <div className="BottomImages">
                        <Plx parallaxData={ laptopMiddleParallaxData }>              
                          <img src={LaptopAon} className="BottomImageThreeMiddle" alt="Laptop #3"/>
                        </Plx>                            
                        <Plx parallaxData={ laptopRightParallaxData }>                            
                          <img src={PhoneAon} className="BottomImageThreeRight" alt="Laptop #2"/>
                        </Plx>
                        <Plx parallaxData={ laptopLeftParallaxData }>                            
                          <img src={IpadSF} className="BottomImageThreeLeft" alt="Laptop #2"/>
                        </Plx>

                  </div>

      </div>

        {/*Big Laptop Block*/}
        <div className="ContentBlock BigLaptopBlock">
            <div className="LaptopExpander">
                <Plx parallaxData={ BigLaptopParallaxData } className="BigLaptopWrapper">
                {/*<img src={BigLaptop} alt="Big Laptop"/>*/}
                  <video autoPlay loop muted playsInline preload="metadata">
                    <source src={LaptopSFVideo}/>                          
                  </video>
                </Plx>
            </div>
        </div>

      {/*Testimonials*/}
      <div className="ContentBlock Centered MasonaryBlock BlackBlock TestimonialBlock">

      {/*        
      <Plx parallaxData={masonaryHeaderCenterParallaxData}>
      <h1 className="Centered FullWidth">Clients Called Us...</h1>
      </Plx>

      <div className="MasonaryContent FullWidth">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ultrices, ipsum quis commodo scelerisque, purus velit feugiat ipsum, a aliquam nisl elit quis dolor. Fusce nec tortor sed metus gravida porttitor. Nam justo ligula, laoreet et elementum sagittis, scelerisque in libero. Nam in purus metus. Nunc tristique ligula quis leo accumsan pulvinar.</p>
      </div>
      */}

      <div className="VerticalLineContainer">
      <Plx parallaxData={verticalLineTestimonialParallaxData}>
          <div className="VerticalLine"></div>
      </Plx> 
      </div>

      <div className="MasonaryContainer">
        <div className="MasonaryTile BioTile">
          <Plx parallaxData={ bioTile1ParallaxData } >
          <img src={LogoSF} alt="Stroz Friedberg"/>
          </Plx>
          <Plx parallaxData={ masonaryTile1ParallaxData } className="BioTileBio">
          
          <p className="Testimonial">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed commodo, risus eu convallis pharetra, eros massa ullamcorper turpis, ut fringilla dui enim fringilla nulla. Cras a purus eget purus cursus tempor nec nec ligula. Fusce porttitor porttitor efficitur.</p>
          <h3>Client</h3>
          <h4>CISO</h4>
          </Plx>
        </div>
        <div className="MasonaryTile BioTile">
          <Plx parallaxData={ bioTile2ParallaxData } >
          <img src={LogoSF} alt="Stroz Friedberg"/>
          </Plx>
          <Plx parallaxData={ masonaryTile2ParallaxData }  className="BioTileBio">
          
          <p className="Testimonial">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed commodo, risus eu convallis pharetra, eros massa ullamcorper turpis, ut fringilla dui enim fringilla nulla. Cras a purus eget purus cursus tempor nec nec ligula. Fusce porttitor porttitor efficitur.</p>
          <h3>Client</h3>
          <h4>CISO</h4>
          </Plx>
        </div>
        <div className="ClearBoth"></div>
        <div className="MasonaryTile BioTile">
          <Plx parallaxData={ bioTile3ParallaxData } >
          <img src={LogoSF} alt="Stroz Friedberg"/>              
          </Plx>
          <Plx parallaxData={ masonaryTile3ParallaxData }  className="BioTileBio">
          <p className="Testimonial">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed commodo, risus eu convallis pharetra, eros massa ullamcorper turpis, ut fringilla dui enim fringilla nulla. Cras a purus eget purus cursus tempor nec nec ligula. Fusce porttitor porttitor efficitur.</p>
          <h3>Client</h3>
          <h4>CISO</h4>
          </Plx>
        </div>
        <div className="MasonaryTile BioTile">
          <Plx parallaxData={ bioTile4ParallaxData } >
          <img src={LogoSF} alt="Stroz Friedberg"/>              
          </Plx>
          <Plx parallaxData={ masonaryTile4ParallaxData }  className="BioTileBio">
          <p className="Testimonial">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed commodo, risus eu convallis pharetra, eros massa ullamcorper turpis, ut fringilla dui enim fringilla nulla. Cras a purus eget purus cursus tempor nec nec ligula. Fusce porttitor porttitor efficitur.</p>
          <h3>Client</h3>
          <h4>CISO</h4>
          </Plx>
        </div>

      </div>
      </div>


      {/*Cybersec Service Block*/}              
      <div className="ContentBlock Centered MasonaryBlock PurpleBlock">
     
          <Plx parallaxData={masonaryHeaderCenterParallaxData}>
      <h1 className="Centered FullWidth">Activating <strong>Cybersecurity</strong> Brands</h1>
      </Plx>

          <div className="ColumnCount3 MarginTop10 FullWidth">
                <ul className="no-bullet bold">
                <li>Brand advisory</li>
                <li>Strategy</li>
                <li>Branding &amp; design
                    <ul>
                    <li>Brand positioning</li>
                    <li>Brand design</li>
                    <li>Core messaging</li>
                    <li>Name generation</li>
                    </ul>
                </li>    
                <li className="break-before">Marketing strategy &amp; planning</li>
                <li>Go-to-market activation</li>
                <li>Creative direction</li>
                <li>Content strategy &amp; planning</li>
                <li>Sales enablement</li>
                <li className="mobile-break-before">Social Media strategy</li>
                <li>Channel management</li>
                <li>MarTech integration</li>
                <li className="break-before">Digital production
                    <ul>
                    <li>UX design</li>
                    <li>Website build</li>
                    <li>Video / Motion graphics</li>
                    <li>App / Digital platforms</li>
                    </ul>
                </li>
                <li>Advertising concepts</li>
                <li>Integrated campaigns</li>
                <li>Analytics</li>
                </ul>
                    </div>
        </div>
        </>
      )
    }
}